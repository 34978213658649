<template>
  <b-card>
    <div slot="header">
      <strong>Invoice Info</strong>
      <div class="card-header-actions">
        <slot name="action" />
      </div>
    </div>
    <b-row>
      <b-col sm="12">
        <span>
          Invoice No
          <span class="float-right font-weight-bold">{{ data.invoiceNo }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Account#
          <span class="float-right font-weight-bold">{{
            data.account.accountId
          }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Account Name
          <span class="float-right font-weight-bold">{{
            data.account.name
          }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Period
          <span class="float-right font-weight-bold">{{ period }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Electricity Usage
          <span class="float-right font-weight-bold">{{ data.usage }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          GST (7%)
          <span class="float-right font-weight-bold">{{ data.gst }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Sub - Total
          <span class="float-right font-weight-bold">{{ data.subTotal }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Additional Deposit Required
          <span class="float-right font-weight-bold">{{
            data.additionalDeposit
          }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Late Payment Charges (1%)
          <span class="float-right font-weight-bold">{{
            data.latePaymentCharge
          }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <span>
          Current Charges (Inclusive of GST)
          <span class="float-right font-weight-bold">{{
            data.totalAmount
          }}</span>
        </span>
      </b-col>
      <b-col sm="12">
        <b-button @click="handleDownload" class="float-right mt-2" size="sm"
          >Download</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <hr />
        <h6>Summary of meter(s)</h6>
        <b-table-simple hover responsive>
          <b-thead head-variant="light">
            <b-tr>
              <b-th rowspan="2" class="align-middle">Meter Serial#</b-th>
              <b-th colspan="2" class="text-center">Previous</b-th>
              <b-th colspan="2" class="text-center">Current</b-th>
              <b-th rowspan="2" class="align-middle text-right"
                >Current Usage</b-th
              >
              <b-th rowspan="2" class="align-middle text-right">Rate</b-th>
              <b-th rowspan="2" class="align-middle text-right">Amount</b-th>
            </b-tr>
            <b-tr>
              <b-th>Reading Date</b-th>
              <b-th class="text-right">Reading Kwh</b-th>
              <b-th>Reading Date</b-th>
              <b-th class="text-right">Reading Kwh</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="row in data.items" :key="row.meter.id">
              <b-td>{{ row.meter.serialNo }}</b-td>
              <b-td>{{ dateFormat(row.previousReadingDate) }}</b-td>
              <b-td class="text-right">{{
                numberFormat(row.previousReadingKwh, 0)
              }}</b-td>
              <b-td>{{ dateFormat(row.currentReadingDate) }}</b-td>
              <b-td class="text-right">{{
                numberFormat(row.currentReadingKwh, 0)
              }}</b-td>
              <b-td class="text-right">{{
                numberFormat(row.currentUsageKwh, 0)
              }}</b-td>
              <b-td class="text-right">{{ numberFormat(row.kwhRate) }}</b-td>
              <b-td class="text-right">{{
                numberFormat(row.usageAmount)
              }}</b-td>
            </b-tr>
            <b-tr v-if="data.length == 0">
              <b-td colspan="8">
                <div class="text-center p-5">
                  <img
                    :src="require('@/assets/svg/empty.svg')"
                    height="200vh"
                    class="mb-10"
                  />
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { dateFormat, parseMonth, numberFormat } from "@/shared/utils";
export default {
  props: {
    data: Object,
  },
  computed: {
    period() {
      const self = this;
      return `${parseMonth(self.data.periodMonth)}/${self.data.periodYear}`;
    },
  },
  methods: {
    dateFormat,
    numberFormat,
    handleDownload() {
      const self = this;
      self.$store
        .dispatch("apis/download", {
          url: `/transaction/invoice/download/${self.$route.params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: "File not found",
            });
          } else {
            let fileURL = window.URL.createObjectURL(new Blob([response])),
              fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", self.data.fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        });
    },
  },
};
</script>