<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12">
        <InvoiceInfo :data="invoice">
          <template slot="action">
            <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
          </template>
        </InvoiceInfo>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InvoiceInfo from "./InvoiceInfo.vue";

export default {
  components: {
    InvoiceInfo,
  },
  data: () => ({
    route: {
      form: "InvoicesHistory",
      table: "Invoices",
    },
    invoice: {
      account: {
        accountId: null,
        name: null,
      },
      additionalDeposit: null,
      gst: null,
      invoiceNo: null,
      latePaymentCharge: null,
      outstandingBalance: null,
      paymentDueDate: null,
      periodMonth: null,
      periodYear: null,
      previousDueAmount: null,
      subTotal: null,
      totalAmount: null,
      usage: null,
      fileName: null,
      items: [],
    },
  }),
  created() {
    const self = this;

    self.get();
  },
  methods: {
    get() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/invoice/${self.$route.params.id}`,
          params: {
            withPayment: true,
            buildingId: self.$route.params.buildingId,
          },
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            self.invoice = {
              account: {
                accountId: response.data.account.accountId,
                name: response.data.account.name,
              },
              additionalDeposit: response.data.additionalDeposit,
              gst: response.data.gst,
              invoiceNo: response.data.invoiceNo,
              latePaymentCharge: response.data.latePaymentCharge,
              outstandingBalance: response.data.outstandingBalance,
              paymentDueDate: response.data.paymentDueDate,
              periodMonth: response.data.periodMonth,
              periodYear: response.data.periodYear,
              previousDueAmount: response.data.previousDueAmount,
              subTotal: response.data.subTotal,
              totalAmount: response.data.totalAmount,
              usage: response.data.usage,
              fileName: response.data.fileName,
              items: response.data.items,
            };
          }
          loader.hide();
        });
    },
  },
};
</script>
